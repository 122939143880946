import React, { useMemo } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { ILink } from '../../interfaces/interfaces';

export const Link: React.FC<ILink> = ({
  text, to, href, icon, onClick, className,
}) => {
  const content = useMemo(() => {
    let logo;
    if (icon) {
      if (typeof icon === 'string') {
        logo = <img src={icon} alt={text} />;
      } else {
        logo = icon;
      }
    }
    return (
      <>
        {logo}
        {text}
      </>
    );
  }, [icon, text]);

  return to
    ? (
      <RouterLink to={to} onClick={onClick} className={className}>{content}</RouterLink>
    ) : (
      <a href={href} onClick={onClick} target="_blank" rel="noopener noreferrer" className={className}>{content}</a>
    );
};