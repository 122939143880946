import React, {
  useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import clsx from 'clsx';
import { langObserver } from '../LanguageProvider/eventObserver';
import { ILangContext, LangContext } from '../LanguageProvider/LangContext';
import { langmap } from '../LanguageProvider/langmap';
import { ILink } from '../../interfaces/interfaces';
import MenuLink from './MenuLink';

import lngIcon from './icons/lng.svg';
import arwLeft from './icons/arrow_left.svg';
import arwRight from './icons/arrow_right.svg';
import checkMark from './icons/checkmark.svg';

import './styles.scss';

interface IContextMenu {
  links?: Array<ILink>
  rtl?: boolean
  profileImage?: string
}

export const ContextMenu: React.FC<IContextMenu> = ({ links = [], rtl, profileImage }) => {
  const [active, setActive] = useState(false);
  const [submenuActive, setSubmenuActive] = useState(false);
  const messages: ILangContext = useContext(LangContext);
  const intl = useIntl();
  const supportedLocales = useMemo(() => Object.keys(messages)
    .sort((a, b) => a.localeCompare(b))
    .map((key) => ({ language: langmap[key], key })),
    [messages]);

  const handleLocaleClick = useCallback(({ currentTarget: { dataset } }) => {
    if (dataset.locale) {
      localStorage.setItem('dufLocale', dataset.locale);
      langObserver.emit('SET_LOCALE', dataset.locale);
    }
  }, []);

  const handleClick = useCallback(() => {
    // event.stopPropagation();
    // event.nativeEvent.stopImmediatePropagation();
    // setActive(!active);

    // We need timeout here instead of stopPropagation event to close other submenus
    setTimeout(() => setActive(!active), 50);
  }, [active, setActive]);

  const handleLngClick = useCallback((event) => {
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
    setSubmenuActive(!submenuActive);
  }, [submenuActive, setSubmenuActive]);

  const handleClickOutside = useCallback(() => {
    setActive(false);
    setSubmenuActive(false);
  }, [setActive]);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [handleClickOutside]);

  return (
    <div className="duf-contextMenu">
      <div className="duf-contextMenu-button" onClick={handleClick}>
        <div className={clsx('duf-contextMenu-buttonContent', { withImage: profileImage })}>
          {profileImage && <img src={profileImage} alt="profile" className="duf-contextMenu-profileImg" />}
          <div />
          <div />
          <div />
        </div>
        <div className={clsx('duf-contextMenu-glass', { active })} />
        <div className={clsx('duf-contextMenu-menu', { active, rtl })}>
          <ul>
            {submenuActive ? (
              <>
                <li className="duf-language-backBtn" onClick={handleLngClick}>
                  <img src={arwLeft} alt="arrow-left" />
                </li>
                {supportedLocales.map((locale) => (
                  <li
                    key={locale.key}
                    data-locale={locale.key}
                    onClick={handleLocaleClick}
                    className={clsx({ active: intl.locale === locale.key })}
                  >
                    {locale.language}
                    {intl.locale === locale.key && (
                      <img src={checkMark} alt="checked" className="duf-language-checkmark" />
                    )}
                  </li>
                ))}
              </>
            ) : (
              <>
                {links.map((link) => (
                  <MenuLink key={`${link.id || link.to || link.href || link.onClick?.name}_${Math.random()}`} link={link} />
                ))}
                <li className="duf-contextMenu-splitter" />
                <li className="duf-contextMenu-languageItem" onClick={handleLngClick}>
                  <div className="duf-contextMenu-logoWrapper">
                    <img src={lngIcon} alt="language" />
                  </div>
                  <FormattedMessage id="language" defaultMessage="Language" />
                  <img src={arwRight} alt="arrow" className="duf-contextMenu-icon" />
                </li>
              </>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};