import React, {
  useCallback, useState, useEffect, MutableRefObject,
} from 'react';
import clsx from 'clsx';
import { NetworkSelector } from '../NetworkSelector/NetworkSelector';
import { ContextMenu } from '../ContextMenu/ContextMenu';
import { ILink, INetworkSelector } from '../../interfaces/interfaces';
import { Link } from './Link';
import { HeaderLinks } from './HeaderLinks';
import { addIntercomListener } from './intercom';

import './styles.scss';
import './intercom.scss';

const voidCallback = () => {};

interface ILogo {
  img: string | React.ReactNode
  to?: string
  href?: string
  text?: string
  onClick?: () => void
}

type TVoidFunction = () => void ;

interface IHeader {
  className?: string
  profileImage?: string
  logo: ILogo
  links?: Array<ILink>
  menuLinks?: Array<ILink>
  mobileLinks?: Array<ILink>
  networkSelectorProps?: INetworkSelector
  backButton?: React.ReactNode
  children?: React.ReactNode
  walletComponent?: React.ReactNode
  onCloseCallbackRef?: MutableRefObject<TVoidFunction>
}

export const Header: React.FC<IHeader> = ({
  className,
  profileImage,
  logo,
  links = [],
  menuLinks = [],
  mobileLinks,
  networkSelectorProps,
  backButton,
  children,
  walletComponent,
  onCloseCallbackRef,
}) => {
  const [checked, setChecked] = useState(false);
  const changeChecked = useCallback((event) => {
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();

    if (typeof window.Intercom === 'function') {
      window.Intercom(
        'update',
        { hide_default_launcher: !checked },
      );
    }
    setChecked(!checked);
  }, [checked, setChecked]);
  const closeMenu = useCallback(() => setChecked(false), [setChecked]);

  const onClickOutside = useCallback(() => {
    console.log('onClickOutside');
    setChecked(false);
  }, []);

  useEffect(() => {
    if (onCloseCallbackRef) {
      onCloseCallbackRef.current = closeMenu;
    }
  }, [onCloseCallbackRef, closeMenu]);

  useEffect(() => {
    addIntercomListener();
    return () => {
      window.removeEventListener('click', onClickOutside);
    };
  }, [onClickOutside]);

  useEffect(() => {
    if (checked) {
      // We need timeout here instead of stopPropagation event to close other submenus
      setTimeout(() => {
        window.addEventListener('click', onClickOutside);
      }, 50);
    } else {
      window.removeEventListener('click', onClickOutside);
    }
  }, [checked, onClickOutside]);

  return (
    <header className={clsx('duf-header', className)}>
      <div className="duf-header-container">
        <input className="duf-header-menu-btn" type="checkbox" id="menu-btn" checked={checked} onChange={voidCallback} />
        <label className="duf-header-menu-icon" htmlFor="menu-btn" onClick={changeChecked}><span className="duf-header-navicon" /></label>
        {backButton}
        <div className={clsx('duf-header-logo', { noPadding: !!backButton })}>
          <Link icon={logo.img} href={logo.href} onClick={logo.onClick} to={logo.to} />
          <span className="duf-header-logo-text">{logo.text}</span>
        </div>
        <HeaderLinks links={links} />
        <div className={clsx('duf-header-wrapper__right', { checked })}>
          <HeaderLinks links={links} />
          {children}
          <HeaderLinks links={mobileLinks} className="dub-header-mobile-links" />
        </div>
        {networkSelectorProps && <NetworkSelector rtl {...networkSelectorProps} />}
        <div className="duf-header-wallet-container">
          {walletComponent}
          <ContextMenu links={menuLinks} rtl profileImage={profileImage} />
        </div>
      </div>
    </header>
  );
};
